<template>
    <section :class="[enter ? 'show' : '']">
        <TopWaypoints @enterdown="enterDown" @exitup="exitUp"></TopWaypoints>
        <slot></slot>
        <BottomWaypoints @enterup="enterUp" @exitdown="exitDown"></BottomWaypoints>
    </section>
</template>

<script>

    import TopWaypoints from '../components/TopWaypoints'
    import BottomWaypoints from '../components/BottomWaypoints'

    export default {

        components: {
            TopWaypoints,
            BottomWaypoints
        },

        data() {
            return {
                enter: false
            }
        },

        mounted() {
            setTimeout(() => {
                if(this.isAnyPartOfElementInViewport(this.$el)){
                    this.enter = true
                    this.$emit('activesection');
                }
            }, 1150)
            // loader time
        },

        methods: {

            isAnyPartOfElementInViewport(el) {

                const rect = el.getBoundingClientRect();

                const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
                const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

                const vertInView = (rect.top <= (windowHeight - 100)) && ((rect.top + rect.height) >= 100);
                const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

                return (vertInView && horInView);

            },

            enterDown() {
                this.enter = true
                this.$emit('activesection');
            },

            enterUp() {
                this.enter = true
                this.$emit('activesection');
            },

            exitUp() {
                this.$emit('disablesection');
            },

            exitDown() {
                this.$emit('disablesection');
            }

        }

    }

</script>