<template>
    <div class="top-waypoint" v-waypoint="{ active: true, callback: TopWaypoint }"></div>
</template>

<script>

    export default {

        methods: {

            TopWaypoint ({ el, going, direction }) {
                if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP)
                    this.$emit('enterdown')
                if (going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_BOTTOM)
                    this.$emit('exitup')
            }

        }

    }

</script>