<template>

    <parallax :speedFactor="0.18">
        <slot></slot>
    </parallax>

</template>

<script>

    import Parallax from 'vue-parallaxy'

    export default {

        components: {
            Parallax
        }

    }

</script>