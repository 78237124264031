<script>

    import sectionpage from '../components/SectionPage'

    export default {

        components: {
            sectionpage
        },

        data() {
            return {
                tabsWidth: null,
                currentTab: null,
                currentTabWidth: null,
                currentTabOffset: null,
                scalePerc: null
            }
        },

        beforeMount () {
            window.addEventListener('resize', this.handleResize)
        },

        mounted() {
            this.init()
        },

        methods: {

            init() {
                this.handleResize()
                this.checkAnchor()
            },

            checkAnchor() {
                let cat = window.location.hash.substring(1)

                if(!cat)
                    cat = this.getFirstTab()

                setTimeout(() => this.goTo(cat), 300)
            },

            goTo(cat) {
                let elem = this.getCatElem(cat)

                if (!elem)
                    cat = this.getFirstTab()

                window.location.hash = cat
                this.currentTab = cat
                this.lineUpdate()
            },

            getFirstTab() {
                return this.$refs.tabs.firstChild.getAttribute('data-hash')
            },

            getCatElem(cat) {
                return document.querySelector('[data-hash='+cat+']')
            },

            lineUpdate() {
                let elem = this.getCatElem(this.currentTab)
                this.scalePerc = (elem.clientWidth / this.tabsWidth)
                this.currentTabOffset = elem.offsetLeft
            },

            handleResize() {
                this.tabsWidth = this.$refs.tabs.clientWidth
                if (this.currentTab != null)
                    this.lineUpdate()
            },


        }

    }

</script>