<script>

    import sectionpage from '../components/SectionPage'
    import youtube from '../components/Youtube'

    export default {

        components: {
            sectionpage,
            youtube
        },

        data(){
            return{
                totalLightImg: null,
                openOverlay: false,
                currentImg: null
            }
        },

        mounted() {
            this.totalLightImg = this.$refs.masonryLight.$el.getElementsByClassName("thumb").length
            window.addEventListener("keydown", this.keyPress, false)
        },

        methods: {

            splitLetter(value) {
                return value.split('').map((letter) => {
                    if (letter == ' ')
                        letter = '&nbsp;'
                    return '<span>' + letter + '</span>'
                }).join('')
            },

            targetImg(num) {
                document.body.classList.add("no-scrl")
                this.openOverlay = true
                setTimeout( () => {this.currentImg = num}, 250)
            },

            prevImg() {
                this.currentImg--
                if (this.currentImg < 1)
                    this.currentImg = this.totalLightImg
            },

            nextImg() {
                this.currentImg++
                if (this.currentImg > this.totalLightImg)
                    this.currentImg = 1
            },

            closeLight() {
                document.body.classList.remove("no-scrl")
                this.currentImg = null
                setTimeout( () => {this.openOverlay = false}, 250)
            },

            keyPress(event) {
                if (event.keyCode == 39 && this.openOverlay)
                    this.nextImg()
                if (event.keyCode == 37 && this.openOverlay)
                    this.prevImg()
                if (event.keyCode == 27)
                    this.closeLight()
            }

        }

    }

</script>