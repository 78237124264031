<script>

    import sectionpage from '../components/SectionPage'
    import parallax from '../components/Parallax'

    export default {

        components: {
            sectionpage,
            parallax
        },

        data() {
            return {
                windowWidth: null,

                activeTab: 0,
                interval: '',
                isPaused: false,

                activeProject: 1,
                totalProjects: 6,
                xDown: null,
                yDown: null
            }
        },

        beforeMount () {
            window.addEventListener('resize', this.handleResize)
        },

        mounted() {
            this.handleResize()
            this.$refs.projectsWrapper.addEventListener('touchstart', this.handleTouchStart, false)
            this.$refs.projectsWrapper.addEventListener('touchmove', this.handleTouchMove, false);
        },

        methods: {

            splitLetter(value) {
                return value.split('').map((letter) => {
                    if (letter == ' ')
                        letter = '&nbsp;'
                    return '<span>' + letter + '</span>'
                }).join('')
            },

            tabToggle(num){
                this.activeTab = num
                clearInterval(this.interval)
                this.interval = setInterval(function() {
                    this.activeTab++
                    this.activeTabCheck()
                }.bind(this), 6000);
            },

            playSlider() {
                this.isPaused = false
                if (this.activeTab > 0)
                    this.activeTab++
                this.activeTabCheck()
                this.tabToggle(this.activeTab)
            },

            pauseSlider() {
                this.isPaused = true
                clearInterval(this.interval)

            },

            activeTabCheck() {
                if (this.activeTab > 3 && this.windowWidth > 700)
                    this.activeTab = 1
                if (this.activeTab > 2 && this.windowWidth < 701)
                    this.activeTab = 1
            },

            handleTouchStart(evt) {
                this.xDown = evt.touches[0].clientX
                this.yDown = evt.touches[0].clientY
            },

            handleTouchMove(evt) {

                if ( !this.xDown || !this.yDown)
                    return

                let xUp = evt.touches[0].clientX
                let yUp = evt.touches[0].clientY

                let xDiff = this.xDown - xUp
                let yDiff = this.yDown - yUp

                if ( Math.abs( xDiff ) > Math.abs( yDiff ) )
                    if (xDiff > 0 && this.activeProject < this.totalProjects)
                        this.activeProject++
                    if (xDiff < 0 && this.activeProject > 1)
                        this.activeProject--

                this.xDown = null
                this.yDown = null

            },

            goTo(num) {
                this.activeProject = num
            },

            handleResize() {
                this.windowWidth = document.documentElement.clientWidth
            },

        },

    }

</script>