<script>

    import sectionpage from '../components/SectionPage'

    export default {

        components: {
            sectionpage
        },

        methods: {

            splitLetter(value) {
                return value.split('').map((letter) => {
                    if (letter == ' ')
                        letter = '&nbsp;'
                    return '<span>' + letter + '</span>'
                }).join('')
            },

        }

    }

</script>