<script>

    import axios from 'axios'
    import sectionpage from '../components/SectionPage'

    export default {

        data() {
            return {
                checkNeeded: false,

                isSending: false,
                isSent: false,

                contact: {
                    name: '',
                    company: '',
                    mail: '',
                    phone: '',
                    subject: '',
                    message: '',
                    gdpr: '',
                },

                contactErrors: {}
            }
        },

        components: {
            sectionpage
        },

        watch: {
            'contact.message'(val) {
                if (val !== '')
                    delete this.contactErrors.message
            }
        },

        methods: {

            submit() {
                let action = this.$refs.contactform.getAttribute('action')
                let data = this.contact

                this.isSending = true
                this.isSent = false

                axios.post(action,data)
                    .then((res) => setTimeout(() => {
                        this.checkContactResponse(res)
                    }, 800))
                    .catch((err) => {
                        console.log(err)
                        this.isSending = false
                        this.isSent = false
                    })

            },

            checkContactResponse(res) {
                this.isSending = false
                let data = res.data

                if(!data.success && data.validator !== undefined)
                    this.contactErrors = data.validator

                if(data.success) {
                    this.contactErrors = {}
                    this.isSent = true
                }
            },

            splitLetter(value) {
                return value.split('').map((letter) => {
                    if (letter == ' ')
                        letter = '&nbsp;'
                    return '<span>' + letter + '</span>'
                }).join('')
            },

            checkValid(event) {

                let tag = event.target.tagName
                let type = event.target.getAttribute('type')
                let required = event.target.getAttribute('required')
                let name = event.target.name

                if (this.checkNeeded){

                    if (type == 'text' || type == 'tel' || tag == 'SELECT'){
                        if (event.target.value){
                            this.isValid(event)
                            delete this.contactErrors[name]
                        }
                        if (!event.target.value){
                            this.isInValid(event)
                        }
                    }

                    if (type == 'mail'){
                        if (this.validateEmail(event.target.value)){
                            this.isValid(event)
                            delete this.contactErrors[name]
                        }
                        if (!this.validateEmail(event.target.value)) {
                            this.isInValid(event)
                        }
                    }

                }

            },

            needCheck(event) {
                if (event.target.value || event.target.parentElement.classList.contains('invalid'))
                    this.checkNeeded = true
                if (!event.target.value && !event.target.parentElement.classList.contains('invalid'))
                    this.checkNeeded = false
                this.checkValid(event)
            },

            isValid(event) {
                event.target.parentElement.classList.remove('invalid')
                event.target.parentElement.classList.add('valid')
            },

            isInValid(event) {
                event.target.parentElement.classList.remove('valid')
                event.target.parentElement.classList.add('invalid')
            },

            checkOptn(event) {
                let name = event.target.getAttribute('name')
                delete this.contactErrors[name]
            },

            validateEmail(email) {
              var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(email);
            },

        }

    }

</script>