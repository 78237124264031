<script>

    export default {

        data() {
            return {
                isLoading: true
            }
        },

        mounted() {
            this.init()
            setTimeout(() => this.enableBrowsing(), 1400)
        },

        methods: {

            init() {
                document.body.classList.add("no-scrl")
                document.addEventListener('touchmove', this.preventDefault, { passive: false }, false)
            },

            enableBrowsing() {
                this.isLoading = false
                document.body.classList.remove("no-scrl")
                document.removeEventListener('touchmove', this.preventDefault, { passive: false }, false)
            },

            preventDefault(event) {
                event.preventDefault(); 
            }

        }

    }

</script>