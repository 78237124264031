<script>

    import CookieManager from '../lib/CookieManager'

    export default {

        data() {
            return {
                BannerUp: false
            }
        },

        mounted() {
            if(CookieManager.getCookie('accept-agreement'))
                return

            setTimeout(() => this.toggleBanner(), 2000)
        },

        methods: {

            toggleBanner() {
                this.BannerUp = !this.BannerUp
            },

            agree() {
                CookieManager.setCookie('accept-agreement', 'true', 1)
                this.toggleBanner()
            }

        }

    }

</script>