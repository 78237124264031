<script>

    import sectionpage from '../components/SectionPage'

    export default {

        components: {
            sectionpage
        }

    }

</script>