<script>

    export default {

        data() {
            return {
                fullLogo: false,
                lineMultiplier: null,
                activeNav: false,
                defaultNav: false
            }
        },

        methods: {

            enterLogo() {
                this.fullLogo = true
            },

            leaveNav() {
                this.fullLogo = false
            },

            enterSubNav(num) {
                this.lineMultiplier = num - 1
            },

            leaveSubNav() {
                this.lineMultiplier = null
            },

            toggleNav() {

                window.scrollTo(0, 0)

                this.activeNav = !this.activeNav

                if (this.activeNav){
                    this.defaultNav = false
                    document.body.classList.add("no-scrl")
                    document.addEventListener('touchmove', this.preventDefault, { passive: false }, false)
                }
                if (!this.activeNav){
                    this.defaultNav = true
                    document.body.classList.remove("no-scrl")
                    document.removeEventListener('touchmove', this.preventDefault, { passive: false }, false)
                }

            },

            preventDefault(event) {
                event.preventDefault(); 
            },

        }

    }

</script>