<template>
    <div class="bottom-waypoint" v-waypoint="{ active: true, callback: BottomWaypoint }"></div>
</template>

<script>

    export default {

        methods: {

            BottomWaypoint ({ el, going, direction }) {
                if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_BOTTOM)
                    this.$emit('enterup')
                if (going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_TOP)
                    this.$emit('exitdown')
            }

        }

    }

</script>