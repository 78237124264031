<template>

    <div class="vid-wrapper">
      <youtube :video-id="videoId" :player-vars="playerVars" ref="youtube"></youtube>
      <div class="play-overlay" @click="playVideo" :class="[ hiddenPoster ? 'hide' : '' ]">
        <slot></slot>
        <button>
          <svg width="32px" height="36px" viewBox="0 0 36 40">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-699.000000, -805.000000)" fill="#FFFFFF">
                <polygon points="735 825 699 845 699 805"></polygon>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>

</template>

<script>
  
    import Vue from 'vue'
    import VueYoutube from 'vue-youtube'
    Vue.use(VueYoutube)

    export default {

      props: {

        videoId: String,

      },

      data() {
        return {
          playerVars: {
            controls: 1,
            showinfo: 0,
            modestbranding: 0,
            autohide: 1,
            rel: 0
          },
          hiddenPoster: false
        }
      },

      methods: {

        playVideo() {
          this.player.playVideo()
          this.hiddenPoster = true
        }

      },

      computed: {

        player () {
          return this.$refs.youtube.player
        }

      }

    }

</script>