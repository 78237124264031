<script>

    import sectionpage from '../components/SectionPage'
    import youtube from '../components/Youtube'

    export default {

        components: {
            sectionpage,
            youtube
        }

    }

</script>