import "babel-polyfill"
import Vue from 'vue'
import VueTextareaAutosize from 'vue-textarea-autosize'
import VueMasonry from 'vue-masonry-css'
import VueWaypoint from 'vue-waypoint'

import loader from './partials/Loader'
import navigation from './partials/Navigation'
import banner from './partials/Banner'

import homepage from './pages/HomePage'
import newspage from './pages/NewsPage'
import newsdetailspage from './pages/NewsDetailsPage'
import projectspage from './pages/ProjectsPage'
import projectdetailspage from './pages/ProjectDetailsPage'
import aboutpage from './pages/AboutPage'
import contactpage from './pages/ContactPage'
import cgupage from './pages/CguPage'

Vue.use(VueTextareaAutosize)
Vue.use(VueMasonry)
Vue.use(VueWaypoint)

var SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing)

const vm = new Vue({

    components: {

      loader,
      navigation,
      banner,
      homepage,
      newspage,
      newsdetailspage,
      projectspage,
      projectdetailspage,
      aboutpage,
      contactpage,
      cgupage

    },

    comments: true

}).$mount('#app')


document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, false);
var lastTouchEnd = 0;

document.documentElement.addEventListener('touchend', function (event) {
  var now = (new Date()).getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);